<template>
<div>
  <b-card-code
    title="Điểm thi"
  >
    <div>
      <vue-good-table
        class="mb-2"
        mode="remote"
        :columns="columns"
        :rows="rows"
        :line-numbers="true"
      >
        <div slot="emptystate" style="text-align: center; font-weight: bold">
          Không có bản ghi nào !
        </div>
        
        <template slot="table-row" slot-scope="props">
          <!-- Render action button based on row condition -->
          <span v-if="props.column.field === 'action'">
          <b-button
            variant="primary"
            class="btn-icon"
            size="sm"
            @click="showModal(props.row)"
            v-if="props.row.trangThai !== 1 && props.row.trangThai !== 2"
          >
            <feather-icon v-b-tooltip.hover title="Gửi yêu cầu phúc khảo" icon="SendIcon"></feather-icon>
          </b-button>
        </span>
          
          <!-- Render trangThai column -->
          <span v-else-if="props.column.field === 'trangThai'">
          {{ getNameStatus(props.row.trangThai) }}
        </span>
          
          <!-- Render other columns -->
          <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
        </template>
      </vue-good-table>
      
      <!-- Button below the table -->
      <span class="mt-2">
        <span class="font-medium-2 font-weight-bolder">
          Thanh toán lệ phí phúc khảo
        </span>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          @click="showPaymentModal()"
        >
          <feather-icon
            icon="DollarSignIcon"
            class=""
          />
          <span> Thanh toán</span>
        </b-button>
      </span>
    </div>
    
    <b-modal
      id="checkExaminationModal"
      centered
      title="Phúc khảo điểm thi"
      size="lg"
      body-class="position-static"
    >
      <span class="text-danger mb-2">* Thí sinh cần phải thanh toán lệ phí phúc khảo sau khi gửi phúc khảo.</span>
      <b-form class="mt-2">
        <b-form-group label-for="name-input">
          <template v-slot:label>
            Nhập lý do <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Lý do"
            rules="required"
          >
            <b-form-input
              id="name-input"
              placeholder="Lý do phúc khảo..."
              v-model="reason"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon"/> Gửi yêu cầu
            </span>
          </b-button>
          
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('checkExaminationModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon"/> Hủy
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      id="checkPaymentExaminationModal"
      centered
      title="Thanh toán lệ phí phúc khảo"
      size="lg"
      body-class="position-static"
    >
      <span class="text-danger mb-2">* Thí sinh cần phải thanh toán lệ phí phúc khảo để hoàn thành quy trình phúc khảo.</span>
      <b-card-code
        title="Danh sách các môn thi đăng ký phúc khảo: "
      >
        <vue-good-table
          mode="remote"
          :columns="columnsPayment"
          :rows="rowsPayment"
        >
          <div
            slot="emptystate"
            style="text-align: center; font-weight: bold"
          >
            Không có bản ghi nào !
          </div>
          <template
            slot="table-row"
            slot-scope="props"
          >

          <span v-if="props.column.field === 'chiPhiPhucKhao'">
            {{ formatCurrency(props.row.chiPhiPhucKhao) }}
          </span>
            <span v-else-if="props.column.field === 'tongTien'">
            {{ formatCurrency(props.row.tongTien) }}
          </span>
            <!-- Column: Common -->
            <span v-else>{{ props.formattedRow[props.column.field] }}</span>
          </template>
        </vue-good-table>
      
      </b-card-code>
      <h4
        class="mt-1 mb-2"
      >
        Tổng số tiền thí sinh cần thanh toán cho lệ phí phúc khảo là: <span style="color: red;">{{ formatCurrency(tongTien) }}</span>
      </h4>
      <h4 class="mt-1 mb-2">
        Số tiền đã thanh toán: <span style="color: red">{{ formatCurrency(tongTienDaThanhToan) }}</span>
      </h4>
      <h4
        class="mt-1 mb-2"
      >
        Số tiền phải thanh toán còn lại: <span style="color: red">{{ formatCurrency(tongTienChuaThanhToan) }}</span>
        <span class="ml-1">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          @click="openPaymentTransaction()"
        >
          <feather-icon
            icon="CreditCardIcon"
            class=""
          />
          <span> Thanh toán</span>
        </b-button>
      </span>
      </h4>
      <b-modal
        id="modal-transaction"
        ref="modal-transaction"
        title="Thanh toán lệ phí phúc khảo"
        :hide-footer="true"
        :hide-header="true"
        no-close-on-backdrop
        no-close-on-esc
        size="lg"
      >
        <Payment
          :info-reception="studentInfoReception"
          @success="onTransactionSuccess"
        />
      </b-modal>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('checkPaymentExaminationModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon"/> Hủy
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-card-code>
</div>
</template>

<script>
import {
  BButton, BOverlay, VBTooltip, BTooltip, BModal, BFormInput, BFormGroup
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { mapActions, mapGetters } from 'vuex'
import { getUser } from '@/auth/utils'
import moment from 'moment'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Flag } from '@/const/flag'
import Ripple from 'vue-ripple-directive'
import Payment from '@/views/payment/Payment.vue'
import VNnum2words from 'vn-num2words'

export default {
  components: {
    Payment,
    BCardCode,
    VueGoodTable,
    BButton,
    BTooltip,
    BModal,
    ValidationObserver,
    ValidationProvider,
    BOverlay,
    BFormInput,
    BFormGroup
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      items: [],
      user: getUser(),
      rows: [],
      rowsPayment: [],
      reason: '',
      applicantExaminationRoomId: '',
      studentInfoReception: {},
      tongTien: 0,
      tongTienDaThanhToan: 0,
      tongTienChuaThanhToan: 0,
      filter: {
        applicantStudentId: null,
        admissionRoundId: null,
        currentPage: 1,
        itemsPerPage: 1000,
        code: null,
        name: null,
        status: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'examination/examinationRooms',
    }),
    columns() {
      return [
        {
          label: 'Phương thức xét tuyển',
          field: 'ptxtName',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Môn thi',
          field: 'monThi',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Ngày thi',
          field: 'ngayThi',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Trạng thái phúc khảo',
          field: 'trangThai',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Điểm thi',
          field: 'diemThi',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Điểm sau khi phúc khảo',
          field: 'diemPhucKhao',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
    columnsPayment() {
      return [
        {
          label: 'Phương thức xét tuyển',
          field: 'ptxtName',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Tổng môn phúc khảo',
          field: 'soMonPhucKhao',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Chi phí phúc khảo',
          field: 'chiPhiPhucKhao',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Thành tiền',
          field: 'tongTien',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
  },
  async created() {
    await this.getInitData()
  },
  methods: {
    ...mapActions({
      getDataSource: 'examination/getListExamination',
      updateStatusScore: 'examination/handleReviewScore',
      createApplicantCheckExamination: 'examination/createApplicantCheckExamination',
      createReceipt: 'receipt/createReceipt',
      getReceiptByRoundAndApplicant: 'receipt/getReceiptByRoundAndApplicant',
      getReportCheckExam: 'examination/getReportCheckExam',
    }),
    transformData(data) {
      return data.map(item => ({
        ptxtName: item.admissionFormName,
        monThi: item.admissionSubjectName,
        ngayThi: moment(item.startAt)
          .format('DD-MM-YYYY'),
        diemThi: item.oldScore ? item.oldScore : item.score,
        diemPhucKhao: item.newScore ? item.newScore : '',
        trangThai: item.status,
        id: item.applicantStudentExaminationRoomId,
      }))
    },
    async checkCreateReceipt() {
      const receipt = await this.getReceiptByRoundAndApplicant({
        admission_round_id: this.user.admissionRoundId,
        applicant_student_id: this.user.id,
        type: 2,
      })
      if (receipt.length === 0) {
        await this.fncCreateReceipt()
      } else {
        const receiptPaid = receipt.filter(item => item.status === 2)
        if (receiptPaid) {
          this.tongTienDaThanhToan = receiptPaid.reduce((total, item) => {
            return (total + item.amount)
          }, 0)
          const receiptUnpaid = receipt.find(item => item.status !== 2)
          const idReceiptUnpaid = receiptUnpaid ? receiptUnpaid.id : null
          if (receiptPaid) {
            this.tongTienChuaThanhToan = this.tongTien - this.tongTienDaThanhToan
          }
          if (this.tongTien > this.tongTienDaThanhToan) {
            await this.fncCreateReceipt(idReceiptUnpaid)
          }
        }
      }
    },
    async fncCreateReceipt(idReceipt) {
      let param = {
        admissionRoundId: this.user.admissionRoundId,
        applicantStudentId: this.user.id,
        reason: 'Nộp tiền phúc khảo',
        total: this.tongTien - this.tongTienDaThanhToan,
        amount: this.tongTien - this.tongTienDaThanhToan,
        amountWord: this.getAmountWord(this.tongTien),
        type: 2,
        status: 1,
      }
      if (idReceipt) {
        param = { ...param, ...{ id: idReceipt } }
      }
      const res = await this.createReceipt(param)
      
      if (res.isSuccessful) {
        this.studentInfoReception = res.data
      }
    },
    getAmountWord(amount) {
      if (amount !== null) {
        // eslint-disable-next-line camelcase
        const amount_word = VNnum2words(amount)
        return `${
          amount_word.charAt(0).toUpperCase() + amount_word.slice(1)
        } đồng`
      }
      return null
    },
    formatCurrency(value) {
      if (!value) return '0 Vnđ'
      const formatter = new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
        minimumFractionDigits: 0,
      })
      return formatter.format(value)
        .replace('₫', 'Vnđ')
    },
    
    async openPaymentTransaction() {
      // thực hiện tạo phiếu thu bảng receipt
      this.$bvModal.show('modal-transaction')
    },
    async onTransactionSuccess() {
      this.$bvModal.hide('modal-transaction')
    },
    async getInitData() {
      const params = {
        admissionRoundId: this.user.admissionRoundId,
        applicantStudentId: this.user.id,
      }
      await this.getDataSource(params)
      this.rows = this.transformData(this.dataSources)
    },

    async showModal(dataRow) {
      this.applicantExaminationRoomId = dataRow.id
      this.$bvModal.show('checkExaminationModal')
    },
    async showPaymentModal() {
      this.filter.admissionRoundId = this.user.admissionRoundId
      this.filter.applicantStudentId = this.user.id
      const data = await this.getReportCheckExam(this.filter)
      if (data) {
        this.rowsPayment = data.records
        const results = data.records.reduce((acc, item) => {
          if (!acc[item.ptxtId]) {
            acc[item.ptxtId] = {
              ptxtId: item.ptxtId,
              ptxtName: item.ptxtName,
              soMonPhucKhao: new Set(),
              tongTien: 0,
              chiPhiPhucKhao: 0,
              khtIdSet: new Set(),
            }
          }
          
          acc[item.ptxtId].soMonPhucKhao.add(item.id)
          
          if (true) {
            acc[item.ptxtId].chiPhiPhucKhao = item.gia
          }
          return acc
        }, {})
        Object.values(results)
          .forEach(ptxt => {
            const soMonPhucKhao = ptxt.soMonPhucKhao.size
            ptxt.tongTien = (ptxt.chiPhiPhucKhao * soMonPhucKhao)
            this.tongTien = ptxt.tongTien
            ptxt.soMonPhucKhao = soMonPhucKhao
          })
        const ketQua = Object.values(results)
        this.rowsPayment = ketQua
      }
      if (this.tongTien > 0) {
        await this.checkCreateReceipt()
      }
      this.$bvModal.show('checkPaymentExaminationModal')
      //lấy thông tin phúc khảo + lệ phí phúc khảo
    },
    async handleReviewScore(dataRow) {
      // const param = { id: dataRow.id }
      // const response = await this.updateStatusScore(param)
      // if (response && response.isSuccessful) {
      //   this.showToast('Gửi yêu cầu phúc khảo thành công', 'checkIcon', 'success', 'Thành công')
      //   await this.getInitData()
      // } else {
      //   this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
      // }
    },
    onSave() {
      this.$swal({
        title: 'Bạn muốn gửi yêu cầu phúc khảo?',
        text: 'Vui lòng kiểm tra lại thông tin trước khi gửi yêu cầu',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            this.isLoading = true
            try {
              const response = await this.createApplicantCheckExamination({
                applicantExaminationRoomId: this.applicantExaminationRoomId,
                description: this.reason,
                status: Flag.ACTIVE,
              })
              if (response) {
                const { isSuccessful, message } = response
                if (isSuccessful) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  this.$bvModal.hide('checkExaminationModal')
                  await this.getInitData()
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              }
            } catch (e) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `[${e.code}] ${e.message}`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            } finally {
              this.isLoading = false
            }

          }
        })
    },

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },

    getNameStatus(status) {
      let name = ''
      switch (status) {
        case 1:
          name = 'Đang xử lý'
          break
        case 2:
          name = 'Đã xử lý'
          break
        default:
          name = 'Không'
          break
      }
      return name
    },
  },
}
</script>
